<template>
  <!-- begin:: Header -->
  <div id="dp_header" ref="dp_header" class="header" :class="headerClasses">
    <div
      class="container-fluid d-flex align-items-center justify-content-between bottom-shadow"
    >
      <!-- begin:: Header Menu -->
      <div
        class="header-menu-wrapper header-menu-wrapper-left"
        ref="dp_header_menu_wrapper"
      >
        <div
          v-if="headerMenuEnabled"
          id="dp_header_menu"
          ref="dp_header_menu"
          class="header-menu header-menu-mobile"
          :class="headerMenuClasses"
        >
          <!-- example static menu here -->
          <DPMenu></DPMenu>
        </div>
      </div>
      <!-- end:: Header Menu -->
      <DPTopbar></DPTopbar>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from 'vuex';
import DPLayoutHeader from '@/assets/js/layout/base/header.js';
import DPLayoutHeaderMenu from '@/assets/js/layout/base/header-menu.js';

export default {
  name: 'DPHeader',
  props: {
    breadcrumbs: Array,
  },
  components: {
    DPTopbar: () => import('@/view/layout/header/Topbar.vue'),
    DPMenu: () => import('@/view/layout/header/Menu.vue'),
  },
  mounted() {
    // Init Desktop & Mobile Headers
    DPLayoutHeader.init('dp_header', 'dp_header_mobile');

    // Init Header Menu
    DPLayoutHeaderMenu.init(
      this.$refs['dp_header_menu'],
      this.$refs['dp_header_menu_wrapper']
    );
  },
  computed: {
    ...mapGetters(['layoutConfig', 'getClasses']),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig('header.menu.self.display');
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses('header');
      if (typeof classes !== 'undefined') {
        return classes.join(' ');
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses('header_menu');
      if (typeof classes !== 'undefined') {
        return classes.join(' ');
      }
      return null;
    },
  },
};
</script>
